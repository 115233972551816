.topSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 90px;
  border-top: 1px solid var(--primary-pink);
  padding-top: 50px;
}

.col {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
}

.textSection {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.searchBar {
  height: 50px;
  width: 100%;
}

.h3 {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 0;
}

.p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
  color: var(--text-body);
}

.footerImage {
  border-radius: 30px;
  object-fit: cover;
}

.ctaSection {
  border-top: 1px solid var(--primary-pink);
  border-bottom: 1px solid var(--primary-pink);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  padding: 15px 0px;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.pDescription {
  font-size: 16px;
  font-weight: 400;
  color: var(--text-main);
  margin-bottom: 0;
}

.bottomSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
}

.leftSection {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.logoSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.logoWrapper {
  width: 45px;
  height: 45px;
  background-color: var(--primary-purple);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.logoMark {
  object-fit: contain;
}

.logo {
  height: 25px;
}

.pLogo {
  font-size: 20px;
  font-weight: 500;
  color: var(--text-body);
  margin-bottom: 0;
}

.rightSection {
  display: flex;
  flex-direction: row;
  gap: 60px;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.h4 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
}

.ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.li {
  font-size: 16px;
  font-weight: 400;
  color: var(--text-mute);
}
.liSocials {
  font-size: 16px;
  font-weight: 400;
  color: var(--text-mute);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.socialWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-bg);
  border-radius: 100%;
  width: 30px;
  height: 30px;
}

.social {
  width: 60%;
  height: 60%;
  color: var(--text-body);

  &:hover {
    transform: scale(1.02);
    color: var(--text-main);
  }
}

.link {
  font-size: 16px;
  font-weight: 400;
  color: var(--text-mute);
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
  color: var(--text-main);
}

.bottomFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
  padding: 50px 0;
  border-top: 1px solid var(--primary-pink);
}

.bold {
  font-weight: 500;
}

.span {
  font-size: 16px;
  font-weight: 500;
  color: var(--text-mute);
  text-decoration: none;
}

.coloredSpan {
  color: var(--primary-purple);
}

@media (max-width: 768px) {
  .topSection {
    flex-direction: column;
    gap: 30px;
    padding-top: 30px;
  }

  .footerImage {
    width: 100%;
  }

  .ctaSection {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    padding: 15px 0;
  }

  .bottomSection {
    flex-direction: column;
    gap: 30px;
    align-items: flex-start;
  }

  .pLogo {
    font-size: 18px;
  }

  .rightSection {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .h4 {
    font-size: 18px;
  }
  .bottomFooter {
    padding: 15px 0;
    padding-bottom: 0;
  }
}
