.nav {
  position: sticky;
  top: 0;
  top: 0;
  z-index: 1000;
}

.badge {
  -webkit-margin-before: auto;
  -webkit-margin-after: auto;
  margin-block: auto;
  margin-bottom: 2px;
}

.main {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 75px;
  top: -0.1em;
  z-index: 1000;
  border-bottom: 0.5px solid var(--primary-border);
  transition: 0.2s linear;
  background-color: white;
}

.loadMoreNots {
  text-align: center;
  cursor: pointer;
  color: var(--primary-purple);
  margin: 0.2em;
}

.navbar {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  background-color: white;
  color: black;
  max-width: var(--max-width);
  width: 100%;
}

/* NEW */
.logoSection {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

.logoWrapper {
  background-color: var(--primary-purple);
  border-radius: 0;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logoMark {
  object-fit: contain;
}

.logo {
  object-fit: contain;
  height: 20px;
}

.navBubble {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  gap: 5px;
  font-weight: 500;
  white-space: nowrap;
  text-decoration: none;
  color: var(--text-main);
  padding: 0.5rem 0.75rem;
  border-radius: 30px;
  transition: all 0.2s ease-out;
  cursor: pointer;

  &:active {
    transform: scale(0.95);
    opacity: 0.8;
  }
}

.linkIcon {
  width: 20px;
  height: 20px;
}

/* NEW END */

.overlayDiv {
  position: absolute;
  top: 0;
  right: 0;
}

.cartHeader {
  font-family: "Editorial";
  margin-bottom: 0rem;
  font-size: 14px;
}

.viewCart {
  background-color: var(--primary-bg-light);
  bottom: 0;
  height: 4rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  border-top: 0.5px solid var(--primary-border);
}

.viewCartButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0rem;
  border-radius: 3px;
  width: calc(100% - 2rem);
  cursor: pointer;
  font-size: 14px;
  padding: 10px 20px;
  font-family: "Editorial";
  color: white;
  background-color: var(--text-main);
}

.viewCartButton:hover {
  opacity: 0.8;
}

.buySomeShit {
  height: 100%;
  padding: 1rem;
  margin-bottom: auto;
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  align-items: center;
}

.col {
  display: flex;
  flex-direction: column;
}

.emptyBox {
  width: 70px;
  height: 70px;
}

.buyText {
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

.buyLink {
  color: var(--primary-purple);
  font-size: 14px;
  font-family: "Editorial";
  cursor: pointer;
}

.buyLink:hover {
  opacity: 0.6;
}

.Navbar1 {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  padding-right: 20px;
  padding-left: 20px;

  color: black;
  height: 75px;
  top: 0;
  width: 100%;
  z-index: 1000;
  border-bottom: 0.5px solid var(--primary-border);
  transition: 0.2s linear;
}

.navLinks {
  float: flex-start;
  display: flex;

  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.navLinks1 {
  float: flex-start;
  display: flex;

  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
}

.loginIcon {
  display: inline-block;
  background-color: #e8e8e8;
  padding: 5px;
  color: #99a3a6;
  border-radius: 20px;
  height: 35px;
  width: 35px;
  margin-right: 0.2em;
}

.loginIcon1 {
  color: white;
  height: 25px;
  width: 41px;
  margin-left: 10px;
  margin-right: 1em;
}

.ProfileNav {
  background-color: white;

  width: 100%;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MoveoutNavbarOnScroll {
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  position: fixed !important;
  top: 75px;
  width: 100%;
  z-index: 5;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filterButton {
  display: flex;
  justify-content: center;
  align-items: center;

  color: black;
  height: 25px;
  width: 35px;
  margin-left: 8px;
  border: 0.1px solid rgba(255, 255, 255, 0.364);
  border-radius: 4px;
}

.filterButtonProfile {
  color: black;
  height: 30px;
  height: 25px;
  width: 35px;
  height: 100%;
  margin-right: 0rem;
  padding: 5px;
  border: 1px solid var(--primary-border);
  border-radius: 4px;
}

.filterText {
  color: white !important;
}

.filterSectionNav {
  display: flex;
  justify-content: center;
}

.filterSectionMobile {
  width: 100%;
  display: flex;
  align-items: center;
  height: 46px;
}

.desktop {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 0.7rem;
}

.mobile {
  display: none;
}

.BuyNavbarOnScroll {
  display: none;
}

.mobileHidden {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

@media (max-width: 990px) {
  .mobile {
    display: flex;
  }

  .mobileHidden {
    display: none;
  }

  .navLinks {
    display: none !important;
  }

  .BuyNavbarOnScroll {
    max-width: 100%;
    /* position: fixed; */
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
    align-items: center;
    background-color: white;
    padding-top: 1rem;

    color: black;

    width: 100%;
    z-index: 2;
  }
}

@media (min-width: 990px) {
  .filterSectionMobile {
    display: none;
  }

  .loginIcon {
    display: none;
  }
  .loginIcon1 {
    display: none;
  }
}

/* modal */
.listingCard {
  border-radius: 10px;
  position: relative;
  width: 100%;
}

.details {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 5px;
  padding: 5px;
  display: block;
  height: auto;
  text-align: left;
  width: 12em;
  margin-left: auto;
  margin-right: auto;
}
.offer {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 5px;
  padding: 1em;
  display: block;
  height: auto;
  margin-top: 1em;
  text-align: left;
}

.offerModalBody {
  text-align: center;
  font-weight: 600;
  color: #0e5c31;
}

.offerInput {
  border: 1px solid #0e5c31;
  border-radius: 10px;
  width: 5em;
  height: 1.5em;
  text-align: center;
  margin-left: 0.4em;
  font-weight: 600;
  color: #0e5c31;
  background-color: white;
}

.imgSizeModal {
  margin-left: auto;
  margin-right: auto;
  max-height: 8em;
  min-height: 8em;
  width: inherit;
  object-fit: contain;
}

.profileNotifications {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(45, 169, 226);
  position: absolute;
  font-weight: 600;
  color: white;
  height: 18px;
  width: 18px;

  border-radius: 1000%;
  font-size: 12px;
  top: 5px;
  right: 25px;
}

.bellNotifications {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(45, 169, 226);
  position: absolute;
  font-weight: 600;

  color: white;
  height: 18px;
  width: 18px;
  border-radius: 1000%;
  font-size: 12px;
  top: 5px;
  right: 75px;
}

.bellNotificationsPlus {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(45, 169, 226);
  position: absolute;
  font-weight: 600;

  color: white;
  height: 18px;
  width: 25px;
  border-radius: 12px;
  font-size: 12px;
  top: 5px;
  right: 70px;
}

.cartNotifications {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(45, 169, 226);
  position: absolute;
  font-weight: 600;
  color: white;
  height: 18px;
  width: 18px;

  border-radius: 1000%;
  font-size: 12px;
  top: 5px;
  right: 122px;
}

.cartNotificationsNotLoggedIn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(45, 169, 226);
  position: absolute;
  font-weight: 600;
  color: white;
  height: 18px;
  width: 18px;

  border-radius: 1000%;
  font-size: 12px;
  top: 5px;
  right: 170px;
}

.favNotification {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(45, 169, 226);
  position: absolute;
  font-weight: 600;
  color: white;
  height: 18px;
  width: 18px;

  border-radius: 1000%;
  font-size: 12px;
  top: 5px;
  right: 165px;
}

.menuItemNotification {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(45, 169, 226);
  font-weight: 600;

  color: white;
  height: 20px;
  width: 20px;

  border-radius: 1000%;
  font-size: 12px;
  top: 5px;
  right: 5px;
  margin-left: 5px;
}

/* logo */

.fallBack {
  position: absolute;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 5px;
  top: 70px;
  right: 15px;
  border: 0.5px solid rgba(128, 128, 128, 0.123);
  width: 400px;
  height: 500px;
}

.loadingPlaceholder {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  margin-right: 0.75rem;
  background-image: repeating-linear-gradient(
    -45deg,
    hsl(210, 27%, 96%) 0%,
    hsla(0, 1%, 70%, 0.433) 50%,
    hsl(210, 28%, 93%) 100%
  );
  animation: anim 2s infinite;
}

.placeholderBox {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background-size: 200% 200%;
  background-image: repeating-linear-gradient(
    -45deg,
    hsl(0, 0%, 98%) 0%,
    hsl(0, 0%, 80%) 50%,
    hsl(0, 0%, 85%) 100%
  );
  animation: anim 2s linear infinite;
}

.textPlaceholderLong {
  width: 80%;
  height: 15px;
  border-radius: 5px;
  background-size: 200% 200%;
  background-image: repeating-linear-gradient(
    -45deg,
    hsl(0, 0%, 98%) 0%,
    hsl(0, 0%, 80%) 50%,
    hsl(0, 0%, 85%) 100%
  );
  animation: anim 2s linear infinite;
  margin-bottom: 5px;
}

.textPlaceholderShort {
  width: 40%;
  height: 15px;
  border-radius: 5px;
  background-size: 200% 200%;
  background-image: repeating-linear-gradient(
    -45deg,
    hsl(0, 0%, 98%) 0%,
    hsl(0, 0%, 80%) 50%,
    hsl(0, 0%, 85%) 100%
  );
  animation: anim 2s linear infinite;
}

@keyframes anim {
  0%,
  100% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
}

/* Text */

.space1 {
  margin-right: 1.5rem;
}

.space2 {
  margin-right: 0.5rem;
}

.space3 {
  margin-right: 0.75rem;
}

.text {
  margin-top: auto;
  margin-bottom: auto;
  font-weight: 400;
  white-space: nowrap;

  color: var(--text-main);

  font-size: 14px;
}

.title {
  margin-bottom: 0rem;
  font-weight: 400;
  font-family: "Editorial";
  font-size: 13px;
}

.text1 {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 1em;
  text-decoration: none;
  color: white;
  padding-top: 2px;
}

.textBadge {
  margin-block: auto;
  font-size: 2.2vh;
  text-decoration: none;
  font-weight: 600;
  color: var(--text-main);
}

.text:hover {
  opacity: 0.4;
  cursor: pointer;
}

.navLink {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.textCanvas {
  font-size: 13px;
  font-weight: 400;
  color: var(--text-main);
  text-transform: uppercase;
  margin-bottom: 0rem !important;
}

.textOnSale {
  font-size: 13px;
  font-weight: 400;
  color: var(--primary-purple);
  text-transform: uppercase;
  margin-bottom: 0rem !important;
}

.iconClose {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
}

.navLink1Offcanvas {
  margin-left: 0.5em;
  padding: 4px;
  text-decoration: none;
  color: black;
}

.offCanvasDiv {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
}

.alignCenter {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.sellButton {
  height: 36px;
  background-color: var(--text-main);
  color: white;
  font-family: "Editorial";

  border-radius: 3px;
  padding-left: 1rem;
  font-weight: 400;
  padding-right: 1rem;
}

.sellButtonOffcanvas {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 500;
  text-decoration: none;
  padding: 10px 20px;
  font-family: "Editorial";

  background-color: var(--text-main) !important;
  border-radius: 2px !important;
  font-weight: 400;
}

.sellButton:hover {
  opacity: 0.8;
}

.sellButtonOffcanvas:hover {
  color: white;
  opacity: 0.8;
}

.logout {
  text-decoration: none;
  color: white;
}
.logout:hover {
  font-weight: 500;
  color: white;
}

.notificationNumber {
  position: absolute;
  right: 0;
  top: 0;
  background-color: var(--text-main);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  padding-top: 0.1em;
}

.navbarImg {
  border: 2px solid var(--primary-bg);
  object-fit: cover;
  height: 35px;
  width: 35px;
  border-radius: 9999px;
  cursor: pointer;
}

.cartImg {
  -webkit-margin-before: auto;
  -webkit-margin-after: auto;
  margin-block: auto;
  height: 23px;
  width: 23px;
  margin-right: 15px;
}

.separator1 {
  width: 100%;
  height: 1px;
  background-color: var(--primary-bg);
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;

  position: relative;
}

.iconContainerActive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;

  border-radius: 100%;
  position: relative;
  background-color: var(--primary-bg);
}

.iconContainer:hover {
  border-radius: 100%;
  background-color: var(--primary-bg);
}

.icon {
  width: 24px;
  height: 24px;
  color: #222222;
  cursor: pointer;
}

.bell {
  color: #222222;
  height: 35px;
  width: 35px;
}

.bell:hover {
  cursor: pointer;
  background-color: var(--primary-bg);
}

.bellActive {
  display: inline-block;
  padding: 4px;
  color: white;
  border-radius: 20px;
  height: 35px;
  width: 35px;
  margin-right: 0.7em;
  background-color: #99a3a6;
}

/* CART */

.cartImg:hover {
  /* height: 25px;
	width: 25px; */
  margin-right: 15px;
  cursor: pointer;
}

.chatImg {
  -webkit-margin-before: auto;
  -webkit-margin-after: auto;
  margin-block: auto;
  height: 25px;
  width: 25px;
  margin-right: 15px;
}

.chatImg:hover {
  /* height: 27px;
	width: 27px; */
  margin-right: 15px;
  cursor: pointer;
}

/* Favorites */
.favoriteNavMenu {
  position: absolute;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 5px;
  top: 70px;
  right: 15px;
  border: 0.5px solid rgba(128, 128, 128, 0.123);
  width: 400px;
  z-index: 3;
}

.overflow {
  padding-bottom: 0rem;
  max-height: 30em;
  overflow-y: scroll;
}

.overflowCart {
  max-height: 328px;
  overflow-y: scroll;
}

/* Notification Menu */

.bellNavMenu {
  position: absolute;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 5px;
  top: 70px;
  right: 15px;
  z-index: 3;
  border: 0.5px solid rgba(128, 128, 128, 0.123);
  width: 400px;
}

.notificationNavMenuItem {
  position: relative;
  display: flex;
  text-decoration: none;
  color: black;
  font-size: 1rem;
  border-radius: 5px;
  width: 100%;
  height: auto;
  padding: 0.6em;
}

.notificationNavMenuItem:hover {
  background-color: rgba(233, 233, 233, 0.747);
  color: black;
  cursor: pointer;
}

.notificationHeader {
  display: flex;
  align-items: center;
  color: black;
  background-color: var(--primary-bg-light);
  padding: 0.5rem 1rem;
  border-bottom: 0.5px solid var(--primary-border);
}

.notify {
  position: relative;
  border-bottom: 1px solid var(--primary-border);
}

.plusItems {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: black;
  color: white;
  font-weight: 600;
  height: 1rem;
  width: 2em;
  font-size: 10px;
  padding: 4px;
  border-bottom-left-radius: 7px;
  position: absolute;
  right: 0;
  top: 0;
}

.spinner {
  width: 3em;
  height: 3em;
}

.chatNotification {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--text-main);
}

.notSeen {
  position: relative;
  display: flex;
  text-decoration: none;
  color: black;
  font-size: 1rem;
  border-radius: 5px;
  width: 100%;
  padding: 3px;
  height: auto;
  background-color: rgb(243, 252, 243);
  padding: 0.6em;
}

.colFull {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.notSeen:hover {
  background-color: rgb(214, 237, 214);
  color: black;
  cursor: pointer;
}

.imageWrapper {
  position: relative;
}

.listingImage {
  margin-top: auto;
  margin-bottom: auto;
  height: 50px;
  width: 50px;
  object-fit: cover;
  border-radius: 2px;
  border: 1px solid var(--primary-border);
}

/* Done */

/* SearchBar */

.searchBarClass {
  height: 3em !important;
  width: 16vw;
}

.searchBarClass1 {
  background-color: white !important;

  height: 3em !important;
  width: 100%;
}

/* Profile Menu */

.profileNavMenu {
  position: absolute;
  background-color: white;
  z-index: 200;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 5px;
  top: 70px;
  right: 15px;
  border: 0.5px solid rgba(128, 128, 128, 0.123);
  height: auto;
  width: 282px;
}

.profileNavMenuItem {
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  width: 100%;
  height: 45px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.profileNavMenuItem1:hover {
  color: #222222;
}

.profileNavMenuItemTop {
  position: relative;
  display: flex;
  text-decoration: none;
  color: black;
  border-radius: 8px;
  width: 100%;
  padding: 20px;
}

.profileNavMenuItem1 {
  position: relative;
  display: flex;
  text-decoration: none;
  color: black;
  border-radius: 8px;
  width: 100%;
  padding: 1rem;
}

.smallText {
  margin-bottom: 0rem;
  font-size: 14px;
  font-weight: 500;
}

.profileNavMenuItem:hover {
  background-color: var(--primary-bg);
  color: black;
  cursor: pointer;
}

.nameText {
  font-size: 14px;
  font-weight: 500;
  color: #222222;
  margin-bottom: 5px;
}

.dropDownContent {
  border-top: 1px solid var(--primary-bg);
  text-transform: uppercase;
  font-size: 12px;
  padding-left: 1rem;
  padding-top: 1rem;
  color: #6b6b6b;
}

.nameText:hover {
  color: #222222;
}

.profilePic {
  border: 2px solid var(--primary-bg);
  height: 3em;
  width: 3em;
  object-fit: cover;
  border-radius: 100px;
}

.separator {
  width: 1px;
  background-color: var(--primary-border);
}

.profileInfo {
  display: flex;
  flex-direction: column;
  margin-left: 1em;
  margin-top: auto;
  margin-bottom: auto;
  width: 70%;
  overflow-wrap: break-word;
}

.profileText {
  margin-bottom: 0rem;
  font-size: 12px;
  color: var(--text-mute);
}

/* Done */

.chatNotificationItem {
  display: block;
  text-decoration: none;
  color: black;
  font-size: 1.166rem;
  border-radius: 8px;
  max-width: 15em;
  height: 3.2em;
  overflow: hidden;
  padding: 5px;
  background-color: rgba(236, 236, 236, 0.418);
  /* border: solid rgba(236, 235, 235, 0.788) 1px; */
  margin: 4px;
  min-width: 10em;
}

.chatNotificationItem:hover {
  background-color: rgba(233, 233, 233, 0.747);
  color: black;
  cursor: pointer;
}

.boldText {
  color: #222222 !important;
}

.sent {
  display: flex;
  text-align: end;
  align-items: center;
  font-size: 13px;
  color: #5e5e5e;
  white-space: nowrap;
  margin-bottom: 0rem;
  margin-bottom: 0rem;
}

.notificationText {
  margin-left: 1em;
  font-size: 13px;
  margin-bottom: 0rem;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-width: 220px;

  line-height: 1.2em;
}

.message {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.notificationTextNotSeen {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;

  -webkit-box-orient: vertical;
  margin-left: 1em;
  max-width: 220px;
  font-weight: 400;

  font-size: 13px;
  margin-bottom: 0rem;
  color: var(--text-body);

  line-height: 1.2em;
}

.notificationTextOverflow {
  display: inline-block;
  margin-left: 0.5em;
  max-width: 15em;
  height: 1.5em;
  font-weight: 400;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
  line-height: 1.2em;
}

.searchBar2 {
  background-color: white;
  border: 1px solid var(--primary-border);
  width: 100%;
  height: 3rem;
  min-height: 3rem;
}

.offCanvas {
  max-width: 85% !important;
  overflow-y: scroll;
}

.mainOffDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding-bottom: 2.5rem;
}

.buttonSection {
  padding: 13px 20px;
  border-bottom: 1px solid var(--primary-border);
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
}
.buttonSectionLast {
  padding: 13px 20px;
  padding-bottom: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
}

.navLinkOffcanvas {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  width: 100%;
  padding: 0rem;
  height: 23px;
}

.seeAll {
  color: var(--text-mute);
  font-size: 13px;
}

.rightArr {
  color: #404656;
  margin-left: auto;
}

.navLinkOffcanvas1 {
  text-decoration: none;
}

.box {
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
  margin-left: 25px;
}

.subLinksBox {
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
}

.navLinkOffcanvasSub {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  /* padding: 0.75rem 20px; */
  width: 100%;
  padding-top: 0rem;
  padding-left: 0px;
  /* border-bottom: 1px solid var(--primary-border); */
}
.navLinkOffCanvasSubLast {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  /* padding: 0.75rem 20px; */
  padding-top: 0rem;
  padding-left: 0px;
  padding-bottom: 0rem;
}

.colGap {
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
}

.colGap1 {
  display: flex;
  flex-direction: column;
}

.textCanvasSub {
  font-size: 13px;
  font-weight: 400;
  color: var(--text-main);
  margin-bottom: 0rem !important;
}

.iconDrop {
  margin-left: auto;
  width: 18px;
  height: 18px;
  transition: all 0.2s ease-in-out;
  color: var(--text-main);
}

.flip {
  transform: rotate(180deg);
  margin-left: auto;
  width: 18px;
  height: 18px;
  transition: all 0.2s ease-in-out;
}

.subLinks {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  row-gap: 0.75rem;
}

.textCanvas1 {
  font-size: 13px;
  color: var(--text-main);
  margin-bottom: 0rem !important;
}

.link {
  color: white;
}

.link:hover {
  color: white;
}

.modalTitle {
  position: relative;
  text-align: center;
  width: 100%;
  font-size: 1.3em;
}

.offerInput {
  border: 1px solid #0e5c31;
  border-radius: 10px;
  width: 50%;
  height: 1.5em;
  text-align: center;
  margin-left: 0.4em;
  font-weight: 600;
  color: #0e5c31;
}

.offerModalBody {
  font-size: 1.3em;
  font-weight: 600;
  color: var(--primary-purple);
}

.notTime {
  position: absolute;
  right: 4px;
  bottom: 5px;
  color: gray;
  font-style: italic;
  font-size: 0.8em;
}

.delete {
  position: absolute;
  right: 5px;
  width: 1em;
  height: 1em;
}

.delete:hover {
  transform: scale(1.1);
}

.profile1 {
  margin-left: auto;
}

.profile2 {
  display: flex;
}

/* Icons */

.sellIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--tetradic-green);
  color: white;
  height: 1.5em;
  width: 1.5em;
  padding: 4px;
  border-radius: 100px;
  position: absolute;
  left: 4px;
  bottom: 4px;
}

.sellIconDeleted {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-purple);
  color: white;
  height: 1.5em;
  width: 1.5em;
  padding: 4px;
  border-radius: 100px;
  position: absolute;
  left: 4px;
  bottom: 4px;
}

.sellIconPaid {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--tetradic-yellow);
  color: white;
  height: 1.5em;
  width: 1.5em;
  padding: 4px;
  border-radius: 100px;
  position: absolute;
  left: 4px;
  bottom: 4px;
}

.icon1 {
  color: white;
  width: 80%;
  height: 80%;
  margin-top: 0.1em;
  margin-left: 0.1em;
}
.inquiryIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--tetradic-blue);
  color: white;
  height: 1.5em;
  width: 1.5em;
  padding: 4px;
  border-radius: 100px;
  position: absolute;
  left: 4px;
  bottom: 4px;
}

.consignmentIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-purple);
  color: white;
  height: 1.5em;
  width: 1.5em;
  padding: 4px;
  border-radius: 100px;
  position: absolute;
  left: 4px;
  bottom: 4px;
}

.headerMobile {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 20px;
  justify-content: center;
  /* align-items: center; */
  row-gap: 1.5rem;
  padding-bottom: 0px;
}

.logoMobile {
  height: 20px;
}

@media (max-width: 768px) {
  .headerMobile {
    padding: 1.5rem 20px;
    justify-content: center;
    row-gap: 1.5rem;
    padding-bottom: 0;
  }
}
